.programs-form {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  /* align-items: 'center'; */
  width: 100%;
  gap: 20px;
}

.datepicker {
  width: 100%;
}
