body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // .MuiDataGrid-panel {
  //   position: absolute;
  //   transform: none !important;
  //   right: 24px !important;
  //   top: 182px !important;
  //   left: calc(100% - 600px) !important;
  // }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.drago {
  color: red;
}

* {
  ::-webkit-scrollbar {
    margin: 8px;
    width: 14px;
    height: 14px;
    background: #f9f9f9;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    margin: 8px;
    box-shadow: inset 0 0 2px #f9f9f9;
    border-radius: 4px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-top: 4px solid #f9f9f9;
    border-bottom: 4px solid #f9f9f9;
    border-left: 4px solid #f9f9f9;
    border-right: 4px solid #f9f9f9;
    background: #0079c1;
    &:hover {
      background: #0067a5;
    }
    &:active {
      background: #004b7a;
    }
    border-radius: 14px;
  }
}
